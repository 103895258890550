import React, { useMemo, useRef, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { OptionList } from 'components/optionList/optionList';
import { EmojiPallet } from 'components/emojiPallet/emojiPallet';
import { MessageBox } from 'components/messageBox/messageBox';
import Copy from 'stories/assets/copy.png';
import Reply from 'stories/assets/reply.png';
import Report from 'stories/assets/report.png';
import Ban from 'stories/assets/icon-sound-off.png';
import Delete from 'stories/assets/garbage.png';
import mulSelect from 'stories/assets/mul-selected-primaryx.png';
import { Message } from 'stories/config';
import { useEmojis } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { roles } from 'definitions';
import copy from 'copy-to-clipboard';
import { showToast } from 'utils';
import { EmojiPalletHandler } from '../emojiPallet/emojiPallet';
export interface OptionBarProps {
  currentMessage: Message;
  isOpen: boolean;
  setIsOpen: (status) => void;
  onReply: () => void;
  onPressDel?: () => void;
  onBanSelect?: (uid: number, name: string, role: number) => void;
  sendReactionToMsg?: (mid, eid) => void;
  setShowMul?: Dispatch<React.SetStateAction<boolean>>;
}

export const OptionBar = ({
  currentMessage,
  isOpen,
  setIsOpen,
  onReply,
  onPressDel,
  onBanSelect,
  sendReactionToMsg,
  setShowMul,
}: OptionBarProps) => {
  const {
    _id,
    text = '',
    images,
    createdAt,
    imageDomain,
    avatar,
    role,
  } = currentMessage;
  const { emojis } = useEmojis();
  const emojiRef = useRef<EmojiPalletHandler>();
  const roleInfo = useSelector((state: RootState) => state.chatroom.roleInfo);
  const navigate = useNavigate();

  const options = [
    {
      image: Copy,
      text: '复制',
      onClick: () => {
        copy(text);
        showToast('复制成功');
        setIsOpen(false);
      },
    },
    {
      image: Reply,
      text: '回复',
      onClick: () => {
        onReply && onReply();
        setIsOpen(false);
      },
    },
    {
      image: Report,
      text: '举报',
      onClick: () => {
        navigate('/report', {
          state: {
            messageId: _id,
            text,
            images,
            avatar: imageDomain + avatar,
          },
        });
      },
    },
  ];

  const banOption = {
    image: Delete,
    text: '删除',
    onClick: () => {
      onPressDel && onPressDel();
    },
  };

  const deleteOption = {
    image: Ban,
    text: '禁言',
    onClick: () => {
      onBanSelect &&
        onBanSelect(currentMessage?.uid, currentMessage.userName, role);
      setIsOpen(false);
    },
  };

  const multiDeleteOption = {
    image: mulSelect,
    text: '多选',
    onClick: () => {
      setShowMul && setShowMul(true);
      setIsOpen(false);
    },
  };

  const optionsData = useMemo(() => {
    if (roleInfo?.role === roles.member) {
      return options;
    }
    const adminOptions = options;
    if (roleInfo?.bp === 1) {
      adminOptions.push(banOption);
    }
    if (roleInfo?.dum === 1 && role === roles.member) {
      adminOptions.push(deleteOption);
    }
    if (roleInfo?.dam === 1 && role !== roles.member) {
      adminOptions.push(deleteOption);
    }

    adminOptions.push(multiDeleteOption);
    return adminOptions;
  }, [roleInfo, options, text]);

  const handleClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  const onChooseEmoji = (emoji) => {
    setIsOpen(false);
    sendReactionToMsg(currentMessage?.mid, +emoji?.id);
  };

  return (
    <div
      className='window-max-confg h-dvh fixed z-[90] bg-black/[0.6] top-0 flex flex-col justify-center items-center'
      onClick={handleClose}
    >
      <div
        className=' w-fit mr-5 mb-2.5 z-[99]'
        onClick={(e) => e.stopPropagation()}
      >
        <OptionList data={optionsData} />
      </div>
      <div className='w-fit mr-[17px] mb-2.5'>
        <MessageBox
          content={text}
          photos={images}
          isLeft={false}
          preview={true}
        />
      </div>
      <div
        className='w-fit mr-5 mb-2.5 z-[99]'
        onClick={(e) => e.stopPropagation()}
      >
        <EmojiPallet emojis={emojis} ref={emojiRef} onChoose={onChooseEmoji} />
      </div>
    </div>
  );
};
