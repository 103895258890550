import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IChatService } from './chat';

type mutedUserPayload = {
  isBan: boolean;
  rid: number;
  uid: number;
  type?: number;
};

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<IChatService.uploadRes, mutedUserPayload>({
    query: ({ isBan, ...args }) => {
      return {
        url: isBan ? '/api/v1/room/userMuted' : '/api/v1/room/cancelUserMuted',
        method: 'POST',
        body: {
          ...args,
        },
      };
    },
  });
