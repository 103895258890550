import React from 'react';

const PageWrapper = <P extends Object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const PageWrapperComponent = (props: P) => {
    return (
      <div className='h-full w-full overflow-hidden flex justify-center bg-slate-50'>
        <div className='window-max-confg'>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };

  return PageWrapperComponent;
};

export default PageWrapper;
