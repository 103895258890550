import React, { useMemo, MouseEventHandler } from 'react';
import './modal.css';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ModalActionTypes } from 'definitions';
interface ModalProps {
  title?: string;
  content?: string;
  showCancel?: boolean;
  isOpen: boolean;
  setIsOpen?: (status) => void;
  onConfirm?: () => void;
  modalAction?: ModalActionTypes;
  deleteMid?: number;
}

/**
 * Primary UI component for user interaction
 */
export const Modal = ({
  title,
  content,
  showCancel = true,
  modalAction,
  deleteMid,
}: ModalProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = (e) => {
    navigate(-1);
    e.stopPropagation();
  };

  return (
    <div
      className={`h-dvh window-max-confg fixed z-[90] bg-black/[0.6] top-0 flex flex-col justify-center items-center`}
      onClick={handleClose}
    >
      <div className='relative  w-full max-w-19rem max-h-full min-w-19rem'>
        <div className='relative bg-white rounded-lg shadow'>
          <div className='px-4 py-10'>
            {title && (
              <div className='flex items-center justify-center  rounded-t'>
                <h3 className='text-xl font-semibold text-gray-900'>{title}</h3>
              </div>
            )}

            <p className='text-center leading-relaxed text-333 whitespace-normal break-all'>
              {content}
            </p>
          </div>

          <div className='flex items-center  border-t border-e6  rounded-b'>
            {showCancel && (
              <button className='py-2.5 flex-1 text-center border-r'>
                取消
              </button>
            )}

            <button
              className='py-2.5 flex-1 text-center text-primary font-semibold'
              onClick={(e) => {
                e.stopPropagation();
                const previousLocation = location?.state?.previousLocation;
                navigate(-1);
                setTimeout(() => {
                  navigate(previousLocation?.pathname, {
                    state: {
                      ...previousLocation.state,
                      actionTimestamp: dayjs().valueOf(),
                      modalAction,
                      ...(deleteMid ? { deleteMid } : {}),
                    },
                    replace: true,
                  });
                }, 300);
              }}
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
