import React, { ChangeEventHandler } from 'react';

export interface TextAreaProps {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  isError: boolean;
  label?: string;
  description?: string;
  errorMsg?: string;
  placeholder?: string;
  outLine?: boolean;
  counter?: boolean;
  maxLimit?: number;
}

export const TextArea = (props: TextAreaProps) => {
  const {
    value,
    onChange,
    isError,
    label,
    description,
    errorMsg,
    placeholder,
    outLine = true,
    counter = false,
    maxLimit = 9999,
  } = props;

  const outLineStyle = outLine ? 'rounded border border-ccc py-2' : 'py-3.5';

  return (
    <div className='w-full'>
      {description ?? <p className='text-666 text-base mb-1'>{description}</p>}
      <div className={`px-3.5 bg-white ${outLineStyle}`}>
        {label ?? (
          <label className='text-sm text-333' htmlFor=''>
            {label}
          </label>
        )}
        <textarea
          className='resize-none w-full text-sm text-333 outline-none'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLimit}
          rows={4}
        ></textarea>
        {counter ? (
          <p className='text-xs text-999 text-right'>{`${value.length}/${maxLimit}`}</p>
        ) : null}
      </div>
      {isError ? <p className='text-xs text-primary'>{errorMsg}</p> : null}
    </div>
  );
};
