import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { IChatService } from './chat';

export type mutedPayload = {
  rid: number;
  is_muted: number;
};

const updateRoomMuted = (build: EndpointBuilder<any, any, any>) =>
  build.mutation<IChatService.chatroomDetailRes, mutedPayload>({
    query: (body) => ({
      url: '/api/v1/room/muted',
      method: 'POST',
      body,
    }),
    invalidatesTags: ['fetchChatroomDetail'],
  });

export default updateRoomMuted;
