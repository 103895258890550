import React, { useMemo } from 'react';
import HeaderBack from 'stories/assets/header-back.png';
import { useNavigate } from 'react-router-dom';
interface PageHeaderProps {
  title: string;
  showBack?: boolean;
  headerRight?: () => JSX.Element;
}

export const PageHeader = ({
  title,
  showBack = true,
  headerRight: HeaderRight,
}: PageHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className='border-b border-ea h-[50px] fixed bg-white top-0 z-50 window-max-confg'>
      <div className='h-full flex items-center' data-testid='page_header'>
        <div className='basis-1/3 flex items-center pl-2'>
          {showBack ? (
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={HeaderBack} className='h-[20px] w-[20px]' />
            </button>
          ) : null}
        </div>
        <p className='basis-1/3 text-[18px] text-333 text-center font-semibold one-line-dot'>
          {title}
        </p>
        <div className='basis-1/3 flex justify-end items-center'>
          {HeaderRight && <HeaderRight />}
        </div>
      </div>
    </div>
  );
};
