import React from 'react';
import { ModalsMap, ModalTypes } from 'definitions';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes, useParams } from 'react-router-dom';

const ModalView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const Component = ModalsMap.get(
    (params?.modalType as ModalTypes) ?? ModalTypes.NORMAL
  ).component;
  return (
    <div className='absolute inset-0 flex justify-center z-50'>
      <div
        onClick={() => {
          navigate(-1);
        }}
        className='absolute inset-0 z-40'
        style={{
          background: 'rgba(0, 0, 0, 0.45)',
        }}
      />
      <Component {...location?.state} />
    </div>
  );
};

const ModalContainer = () => {
  return (
    <Routes>
      <Route path='/modal/:modalType' element={<ModalView />} />
    </Routes>
  );
};

export default ModalContainer;
