import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { setRoleInfo } from 'redux/actions';
import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<IChatService.uploadRes, any>({
    query: (body) => {
      return {
        url: '/api/v1/oss/upload',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    },
  });
