import React, { useMemo, useState, Dispatch, useEffect } from 'react';
import { MessageBox } from 'components/messageBox/messageBox';
import { Avatar } from 'components/avatar/Avatar';
import { UserName } from 'components/userName/UserName';
import { Badge } from 'components/badge/Badge';
import iconCheck from 'stories/assets/icon-check.svg';
import mulUnchecked from 'stories/assets/mul-unchecked.png';
import mulChecked from 'stories/assets/mul-checked.png';
import { OptionBar } from 'components/optionBar/OptionBar';
import dayjs from 'dayjs';
import { roles, roleNames } from 'definitions';
import { Message as MessageType } from 'stories/config';
import { setReplyMsg } from 'redux/actions';
import { useAppDispatch } from 'redux/store';
import useEmojis from 'hooks/useEmojis';
import { useLongPress } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useNavigate, useLocation } from 'react-router-dom';
import { ModalsMap, ModalTypes, ModalActionTypes } from 'definitions';

export interface Message {
  _id: number;
  uid: number;
  userName: string;
  avatar: string;
  isMute?: number;
  act: string;
  emojiList: [string, number][];
  replyId?: number;
  replyMsg?: string;
  replyImages?: string[];
  replyName?: string;
  images?: string[];
  text?: string;
  patterns: string[];
  selfReaction: number;
  system: boolean;
  createdAt: string;
  imageDomain: string;
  mid: string;
  is?: string[];
  eid?: number;
}

export interface CurrentUser {
  uid: number;
  name: string;
}

export interface MessageContentProps {
  _id?: number;
  uid?: number;
  userName?: string;
  avatar?: string;
  createdAt: string;
  isLeft: boolean;
  onMultiple?: boolean;
  replyId?: number;
  replyMsg?: string;
  replyImages?: string;
  replyName?: string;
  images?: string[];
  text?: string;
  isRedEnvelope?: boolean;
  role?: number;
  currentMessage?: Message;
  isBan?: boolean;
  onDeleteMessage?: (mids: any[]) => void;
  onBanSelect?: (uid: number, name: string, role: number) => void;
  retrieveReactionToMsg?: (mid, eid) => void;
  sendReactionToMsg?: (mid, eid) => void;
  onPressReactions?: (mid: number) => void;
  setShowMul?: Dispatch<React.SetStateAction<boolean>>;
  onMulSelect?: (mid: number) => void;
}

const EmojiDisplayLength = 5;

export const MessageContent = (props: MessageContentProps) => {
  const { emojiMap } = useEmojis();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    _id,
    uid,
    userName,
    avatar,
    images = [],
    text = '',
    createdAt,
    isLeft,
    replyId = null,
    replyMsg,
    replyImages,
    replyName,
    onMultiple,
    isRedEnvelope,
    role,
    currentMessage,
    onDeleteMessage,
    onBanSelect,
    retrieveReactionToMsg,
    sendReactionToMsg,
    onPressReactions,
    setShowMul,
    onMulSelect,
    isBan = false,
  } = props;
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  const selectTimeStamp = useSelector(
    (state: RootState) => state.chatroom.resetSelectTimeStamp
  );
  const contentText = useMemo(() => {
    // currentMessage?.patterns = [];

    let processText = text;
    currentMessage?.patterns?.forEach((pattern) => {
      processText = processText.replace(pattern, (match) => {
        if (isLeft) {
          return '<span style="color: #f94470">' + match + '</span>';
        }
        return '<span style="font-weight: bold">' + match + '</span>';
      });
    });
    return processText;
  }, [text, currentMessage?.patterns]);

  useEffect(() => {
    if (selectTimeStamp > 0) {
      setSelected(false);
    }
  }, [selectTimeStamp]);

  const attrs = useLongPress(
    () => {
      if (!isBan) setIsOptionOpen(true);
    },
    {
      threshold: 500,
    }
  );

  const selfReaction = useMemo(
    () => currentMessage?.eid,
    [currentMessage?.eid]
  );

  const replayObj =
    replyId !== null
      ? { userName: replyName, photo: replyImages, content: replyMsg }
      : null;

  const contentDirection = isLeft
    ? 'flex-row'
    : 'flex-row-reverse space-x-reverse';

  const checkboxDisplay = onMultiple ? 'block' : 'hidden';

  const handleToggleOption = (status: boolean): void => {
    setIsOptionOpen(status);
  };

  const parseImages = useMemo(
    () =>
      currentMessage?.is?.map((e) => `${currentMessage?.imageDomain}${e}`) ??
      [],
    [currentMessage]
  );

  const filterdEmojiList = useMemo(() => {
    return currentMessage?.emojiList?.filter((e) => e?.[1] > 0) ?? [];
  }, [currentMessage]);

  const getEmojiBgColor = (emoji) => {
    if (isLeft) {
      return selfReaction === +emoji?.[0] ? '#999' : '#eee';
    } else {
      return selfReaction === +emoji?.[0] ? '#f94470' : '#feecf0';
    }
  };

  const getEmojiNumColor = (emoji) => {
    return selfReaction === +emoji?.[0] ? '#fff' : '#666';
  };

  const MoreButtom = ({ currentMessage }) =>
    filterdEmojiList?.length > 4 ? (
      <div
        onClick={() => {
          onPressReactions && onPressReactions(currentMessage?.mid);
        }}
        className='bg-pink-200 rounded-[10px] px-[6px] py-[1px] items-center justify-center'
      >
        <span className='text-666'>···</span>
      </div>
    ) : null;

  return (
    <>
      <div className='w-full flex pr-3'>
        <div
          onClick={() => {
            setSelected((prev) => !prev);
            onMulSelect && onMulSelect(+currentMessage?.mid ?? 0);
          }}
          className={`w-6 h-6 mr-3.5 rounded-full self-center ${checkboxDisplay}`}
        >
          <img src={selected ? mulChecked : mulUnchecked} alt='checked' />
        </div>
        {isLeft ? (
          <div className='pl-2 mr-3.5'>
            <Avatar
              source={avatar}
              size='md'
              hasBorder={false}
              badge={() =>
                [roles.admin, roles.super].includes(role) ? (
                  <Badge text={roleNames[role]} positionType={'bottom'} />
                ) : null
              }
            />
          </div>
        ) : null}

        <div className='space-y-1 flex-1'>
          {isLeft ? (
            <UserName
              userName={userName}
              color={'text-666'}
              size={'sm'}
              currentMessage={currentMessage}
            />
          ) : null}

          <div>
            <div className={`flex space-x-1 ${contentDirection}`}>
              <div {...attrs}>
                <MessageBox
                  content={contentText}
                  isLeft={isLeft}
                  photos={parseImages}
                  replay={replayObj}
                  isRedEnvelope={isRedEnvelope}
                />
              </div>

              <p className='text-xs text-999 self-end'>
                {dayjs(createdAt).format('HH:mm')}
              </p>
            </div>
            <div className={`flex space-x-1 mt-1 ${contentDirection}`}>
              {!isLeft ? <MoreButtom currentMessage={currentMessage} /> : null}

              {filterdEmojiList?.map((emoji, eIdx) =>
                emoji?.[1] > 0 && eIdx < EmojiDisplayLength - 1 ? (
                  <div
                    key={`${emoji?.[1]}${_id}${eIdx}`}
                    className='flex items-center rounded-[10px] px-[6px] py-[1px]'
                    onClick={() => {
                      if (selfReaction === +emoji?.[0]) {
                        retrieveReactionToMsg(currentMessage?.mid, +emoji?.[0]);
                      }
                    }}
                    style={{ backgroundColor: getEmojiBgColor(emoji) }}
                  >
                    <span className='text-xs mr-1'>
                      {emojiMap?.[emoji?.[0]]}
                    </span>
                    <span
                      className='text-xs'
                      style={{ color: getEmojiNumColor(emoji) }}
                    >
                      {emoji?.[1]}
                    </span>
                  </div>
                ) : null
              )}
              {isLeft ? <MoreButtom currentMessage={currentMessage} /> : null}
            </div>
          </div>
        </div>
      </div>
      <OptionBar
        currentMessage={currentMessage as MessageType}
        isOpen={isOptionOpen}
        setIsOpen={handleToggleOption}
        onReply={() => {
          dispatch(setReplyMsg(currentMessage));
        }}
        onPressDel={() => {
          setIsOptionOpen(false);
          navigate(`/modal/${ModalTypes.NORMAL}`, {
            state: {
              previousLocation: location,
              content: '确认删除讯息，删除后不可恢复！',
              modalAction: ModalActionTypes.DELETE_MSG,
              deleteMid: currentMessage?.mid,
            },
          });
        }}
        onBanSelect={onBanSelect}
        sendReactionToMsg={sendReactionToMsg}
        setShowMul={setShowMul}
      />
    </>
  );
};
