import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import Expand from 'stories/assets/expand.png';
import './emojiPallet.css';

interface EmojiPalletProps {
  emojis: any[];
  onChoose?: (emoji) => void;
}

export interface EmojiPalletHandler {
  getValue: () => string;
}

/**
 * Primary UI component for user interaction
 */
export const EmojiPallet = forwardRef<EmojiPalletHandler, EmojiPalletProps>(
  ({ emojis, onChoose }, ref) => {
    const [value, setValue] = useState('');
    const [expand, setExpand] = useState(false);

    const emoji = useMemo(
      () => emojis?.slice().splice(0, expand ? 126 : 6),
      [expand]
    );

    const classNameString = useMemo(() => {
      let classArr = [
        'flex w-full rounded-lg p-2 max-w-72 bg-slate-50 justify-between items-stretch flex-wrap scroll',
        expand ? 'overflow-auto h-40' : 'overflow-hidden h-10',
      ];

      return `${classArr.join(' ')}`;
    }, [expand]);

    useImperativeHandle(ref, () => ({
      getValue: () => value,
    }));

    return (
      <div className={classNameString}>
        {emoji?.map((e, i) => (
          <button
            key={e.id}
            type='button'
            className='bg-transparent w-9 h-7 flex justify-content-center'
            onClick={() => onChoose(e)}
          >
            {e.name}
          </button>
        ))}
        {expand ? null : (
          <button
            type='button'
            className='bg-transparent w-6 h-5'
            onClick={() => setExpand(true)}
            data-testid='pallet_btn'
          >
            <img src={Expand} />
          </button>
        )}
      </div>
    );
  }
);
