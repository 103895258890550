import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { setRoleInfo } from 'redux/actions';
import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<IChatService.userInfoRes, void>({
    query: (params) => {
      return {
        url: '/api/v1/user/getInfo',
        method: 'GET',
        params,
      };
    },
    keepUnusedDataFor: 0,
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      await queryFulfilled;
      const { data } = await queryFulfilled;
      await dispatch(setRoleInfo(data));
    },
  });
