import React, { MouseEventHandler, ReactElement } from 'react';
import { Avatar } from '../avatar/Avatar';
import { Badge } from 'components/badge/Badge';
import { IconButton } from 'components/iconButton/IconButton';

export interface UserProfileItemProps {
  avatar: string;
  userName: string;
  isLatter: boolean;
  role?: string;
  description?: string;
  buttonImg?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  btnHandleClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  avatarBadge?: () => ReactElement;
}

export const UserProfileItem = (props: UserProfileItemProps) => {
  const {
    avatar,
    userName,
    role = null,
    description = null,
    buttonImg = null,
    isLatter,
    handleClick,
    btnHandleClick,
    avatarBadge = null,
  } = props;
  const contentStyle = isLatter ? '' : 'border-b border-slate-50';

  const replaceName =
    userName?.length >= 15 ? userName.slice(0, 7) + '...' : userName;

  return (
    <div
      className={`w-full py-2.5 pl-1 flex justify-between space-x-1 bg-white ${contentStyle}`}
      onClick={handleClick}
      data-testid='profile_item'
    >
      <div className='flex items-center'>
        <Avatar
          source={avatar}
          size={'sm'}
          hasBorder={false}
          badge={avatarBadge}
        />
        <p className='text-sm text-333 ml-[20px]'>{replaceName}</p>
        {role !== null ? <Badge text={role} positionType={'none'} /> : null}
      </div>
      <div className='flex items-center space-x-1'>
        {description !== null ? (
          <p className='text-xs text-999'>{description}</p>
        ) : null}
        {buttonImg !== null ? (
          <IconButton source={buttonImg} onClick={btnHandleClick} />
        ) : null}
      </div>
    </div>
  );
};
