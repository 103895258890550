import React from 'react';
import { useAppDispatch } from 'redux/store';
import { setAtContent } from 'redux/actions';
export interface UserNameProps {
  userName: string;
  color: string;
  size: 'xs' | 'sm' | 'md';
  currentMessage?: any;
}

export const UserName = ({
  userName,
  color,
  size,
  currentMessage,
}: UserNameProps) => {
  const dispatch = useAppDispatch();
  const replaceName =
    userName.length >= 9 ? userName.slice(0, 8) + '...' : userName;

  let textSize = '';
  switch (size) {
    case 'xs':
      textSize = 'text-[13px]';
      break;
    case 'sm':
      textSize = 'text-sm';
      break;
    case 'md':
      textSize = 'text-base';
      break;
  }

  return (
    <p
      onClick={() => {
        if(!currentMessage) return;
        dispatch(
          setAtContent({
            userName: currentMessage?.userName,
            userId: currentMessage?.uid,
          })
        );
      }}
      className={`${textSize} ${color}`}
    >
      {replaceName}
    </p>
  );
};
