import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chunk } from 'lodash';

const PAGE_LIMIT = 40;
const width = window.innerWidth;
const MARGIN = 3;

const dummyEmojis = [
  {
    id: 1,
    name: '😁',
  },
  {
    id: 2,
    name: '😂',
  },
  {
    id: 3,
    name: '😃',
  },
  {
    id: 4,
    name: '😄',
  },
  {
    id: 5,
    name: '😅',
  },
  {
    id: 6,
    name: '😆',
  },
  {
    id: 7,
    name: '😉',
  },
  {
    id: 8,
    name: '😊',
  },
  {
    id: 9,
    name: '😋',
  },
  {
    id: 10,
    name: '😌',
  },
  {
    id: 11,
    name: '😍',
  },
  {
    id: 12,
    name: '😏',
  },
  {
    id: 13,
    name: '😒',
  },
  {
    id: 14,
    name: '😓',
  },
  {
    id: 15,
    name: '😔',
  },
  {
    id: 16,
    name: '😖',
  },
  {
    id: 17,
    name: '😘',
  },
  {
    id: 18,
    name: '😚',
  },
  {
    id: 19,
    name: '😜',
  },
  {
    id: 20,
    name: '😝',
  },
  {
    id: 21,
    name: '😞',
  },
  {
    id: 22,
    name: '😠',
  },
  {
    id: 23,
    name: '😡',
  },
  {
    id: 24,
    name: '😢',
  },
  {
    id: 25,
    name: '😣',
  },
  {
    id: 26,
    name: '😤',
  },
  {
    id: 27,
    name: '😥',
  },
  {
    id: 28,
    name: '😨',
  },
  {
    id: 29,
    name: '😩',
  },
  {
    id: 30,
    name: '😪',
  },
  {
    id: 31,
    name: '😫',
  },
  {
    id: 32,
    name: '😭',
  },
  {
    id: 33,
    name: '😰',
  },
  {
    id: 34,
    name: '😱',
  },
  {
    id: 35,
    name: '😲',
  },
  {
    id: 36,
    name: '😳',
  },
  {
    id: 37,
    name: '😵',
  },
  {
    id: 38,
    name: '😷',
  },
  {
    id: 39,
    name: '😸',
  },
  {
    id: 40,
    name: '😹',
  },
  {
    id: 41,
    name: '😺',
  },
  {
    id: 42,
    name: '😻',
  },
  {
    id: 43,
    name: '😼',
  },
  {
    id: 44,
    name: '😽',
  },
  {
    id: 45,
    name: '😾',
  },
  {
    id: 46,
    name: '😿',
  },
  {
    id: 47,
    name: '🙀',
  },
  {
    id: 48,
    name: '🙅',
  },
  {
    id: 49,
    name: '🙆',
  },
  {
    id: 50,
    name: '🙇',
  },
  {
    id: 51,
    name: '🙈',
  },
  {
    id: 52,
    name: '🙉',
  },
  {
    id: 53,
    name: '🙊',
  },
  {
    id: 54,
    name: '🙋',
  },
  {
    id: 55,
    name: '🙌',
  },
  {
    id: 56,
    name: '🙍',
  },
  {
    id: 57,
    name: '🙎',
  },
  {
    id: 58,
    name: '🙏',
  },
  {
    id: 59,
    name: '✂',
  },
  {
    id: 60,
    name: '✅',
  },
  {
    id: 61,
    name: '✈',
  },
  {
    id: 62,
    name: '✉',
  },
  {
    id: 63,
    name: '✊',
  },
  {
    id: 64,
    name: '✋',
  },
  {
    id: 65,
    name: '✌',
  },
  {
    id: 66,
    name: '✏',
  },
  {
    id: 67,
    name: '✒',
  },
  {
    id: 68,
    name: '✔',
  },
  {
    id: 69,
    name: '✖',
  },
  {
    id: 70,
    name: '✨',
  },
  {
    id: 71,
    name: '✳',
  },
  {
    id: 72,
    name: '✴',
  },
  {
    id: 73,
    name: '❄',
  },
  {
    id: 74,
    name: '❇',
  },
  {
    id: 75,
    name: '❌',
  },
  {
    id: 76,
    name: '❎',
  },
  {
    id: 77,
    name: '❓',
  },
  {
    id: 78,
    name: '❔',
  },
  {
    id: 79,
    name: '❕',
  },
  {
    id: 80,
    name: '❗',
  },
  {
    id: 81,
    name: '❤',
  },
  {
    id: 82,
    name: '➕',
  },
  {
    id: 83,
    name: '➖',
  },
  {
    id: 84,
    name: '➗',
  },
  {
    id: 85,
    name: '➡',
  },
  {
    id: 86,
    name: '➰',
  },
  {
    id: 87,
    name: '🚀',
  },
  {
    id: 88,
    name: '🚃',
  },
  {
    id: 89,
    name: '🚄',
  },
  {
    id: 90,
    name: '🚅',
  },
  {
    id: 91,
    name: '🚇',
  },
  {
    id: 92,
    name: '🚉',
  },
  {
    id: 93,
    name: '🚌',
  },
  {
    id: 94,
    name: '🚏',
  },
  {
    id: 95,
    name: '🚑',
  },
  {
    id: 96,
    name: '🚒',
  },
  {
    id: 97,
    name: '🚓',
  },
  {
    id: 98,
    name: '🚕',
  },
  {
    id: 99,
    name: '🚗',
  },
  {
    id: 100,
    name: '🚙',
  },
  {
    id: 101,
    name: '🚚',
  },
  {
    id: 102,
    name: '🚢',
  },
  {
    id: 103,
    name: '🚤',
  },
  {
    id: 104,
    name: '🚥',
  },
  {
    id: 105,
    name: '🚧',
  },
  {
    id: 106,
    name: '🚨',
  },
  {
    id: 107,
    name: '🚩',
  },
  {
    id: 108,
    name: '🚪',
  },
];

const emojiMap = dummyEmojis.reduce(
  (acc, cur) => ({ ...acc, [cur.id]: cur.name }),
  {}
);

const useEmojis = () => {
  //process data
  const emojis = dummyEmojis;
  const pageList = useMemo(() => chunk(emojis, PAGE_LIMIT), [emojis]);
  const dotWidth = useMemo(
    () => (width - MARGIN * (pageList?.length - 1)) / pageList.length ?? 10,
    [pageList]
  );

  return { emojis, pageList, dotWidth, emojiMap };
};

export default useEmojis;
