import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

interface MessageBoxProps {
  content?: string;
  isLeft?: boolean;
  isRedEnvelope?: boolean;
  photos?: string[];
  replay?: {
    userName: string;
    photo?: string;
    content?: string;
  };
  preview?: boolean;
}

export const MessageBox = ({
  content,
  isLeft = false,
  isRedEnvelope = false,
  photos = [],
  replay = null,
  preview = false,
}: MessageBoxProps) => {
  if (isRedEnvelope) {
    return (
      <div className='bg-red-envelope-msg-bg bg-contain w-[210px] h-[70px] p-[10px]'>
        <div className='w-[125px] ml-[60px]'>
          <p className='text-[14px] text-white one-line-dot mb-[7px] h-[21px]'>
            {content}
          </p>
          <p className='text-white text-[12px] text-center'>领取红包</p>
        </div>
      </div>
    );
  }

  if (isLeft) {
    return (
      <div className='max-w-[312px] bg-ddd relative px-3.5 py-2 rounded-b-[4px] rounded-tr-[4px]'>
        {replay ? (
          <div className='border-l-[2px] border-primary pl-2 flex mb-2'>
            {replay?.photo ? (
              <img
                src={replay?.photo}
                className='h-[40px] w-[40px] mr-2  border border-white'
              />
            ) : null}
            <div className='max-w-[200px]'>
              <p className='text-primary text-[12px] max-w-[154px] one-line-dot'>
                {replay?.userName}
              </p>
              <p className='text-666 text-[12px] one-line-dot'>
                {replay?.content}
              </p>
            </div>
          </div>
        ) : null}

        <p
          className='text-333'
          style={{ lineBreak: 'anywhere' }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
        <div className='w-0 h-0 border-r-[12px] border-r-ddd border-b-[10px] border-b-transparent absolute top-0 -left-[12px]' />
        {!isEmpty(photos) ? (
          <div className='flex gap-[8px] mt-2'>
            {photos?.map((e, eIdx) => (
              <img
                key={e}
                src={e}
                className='w-[68px] h-[68px] border border-ea'
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className='max-w-[312px] bg-primary relative px-3.5 py-2 rounded-b-[4px] rounded-tl-[4px]'>
      {replay ? (
        <div className='border-l-[2px] border-white pl-2 flex mb-2'>
          {replay?.photo ? (
            <img
              src={replay?.photo}
              className='h-[40px] w-[40px] mr-2 border border-white'
            />
          ) : null}
          <div className='max-w-[200px]'>
            <p className='text-white text-[12px] max-w-[154px] one-line-dot'>
              {replay?.userName}
            </p>
            <p className='text-white text-[12px] one-line-dot'>
              {replay?.content}
            </p>
          </div>
        </div>
      ) : null}
      {content ? (
        <p
          className='text-white'
          style={{ lineBreak: 'anywhere' }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      ) : null}
      <div className='w-0 h-0 border-l-[12px] border-l-primary border-b-[10px] border-b-transparent absolute top-0 -right-[12px]' />
      {!isEmpty(photos) ? (
        <div className='flex gap-[8px] mt-2'>
          {photos?.map((e, eIdx) => (
            <img
              key={e}
              src={e}
              className='w-[68px] h-[68px] border border-ea'
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
