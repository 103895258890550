import Config from 'config';

const globalAny: any = global;

globalAny.toast = null;
globalAny.timeDiff = 0;
globalAny.domainDetectJobs = [];
globalAny.fastestDomain = Config.API;
globalAny.allDomainFail = false;
globalAny.imageDomain = '';

export const getToast = () => {
  return globalAny.toast;
};

export const setToast = (toast: any) => {
  globalAny.toast = toast;
};

export const setTimeDiff = (time: number) => {
  globalAny.timeDiff = time;
};

export const getTimeDiff = () => {
  return globalAny.timeDiff;
};

export const getFastestDomain = () => {
  return globalAny.fastestDomain;
};

export const setFastestDomain = (domain: any) => {
  globalAny.fastestDomain = domain;
};

export const addDomainDetectJob = (fn: any) => {
  globalAny.domainDetectJobs.push(fn);
};

export const clearDomainDetectJobs = () => {
  globalAny.domainDetectJobs.map((job: any) => clearTimeout(job));
  globalAny.domainDetectJobs = [];
};

export const setAllDomainFail = (isFail: boolean) => {
  globalAny.allDomainFail = isFail;
};

export const getAllDomainFail = () => {
  return globalAny.allDomainFail;
};

export const setImageDomain = (url: string) => {
  globalAny.imageDomain = url;
};

export const getImageDomain = () => globalAny.imageDomain;
