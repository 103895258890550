import { Modal } from 'components/modal/modal';
import RuleBoard from 'components/modal/ruleBoard';
export const MessageType = {
  MSG: 1,
  REPLY_EMOJI: 3,
  DELETE_MSG: 4,
  BAN_ROOM: 5,
  HISTORY: 6,
  MARQUEE: 7,
  LEAVE: 8,
  AT: 9,
  EMOJI_LIST: 10,
  BAN_USER: 11,
  HEART_BEAT: 12,
  RETRIEVE_EMOJI: 13,
  KICK_OUT: 14,
  BAN_ALL_CHANNEL: 15,
};

export const muteList = [
  { id: 1, data: '5分钟' },
  { id: 2, data: '1小时' },
  { id: 3, data: '1天' },
  { id: 4, data: '7天' },
  { id: 5, data: '30天' },
];

export const roles = {
  super: 1,
  admin: 2,
  member: 3,
};

export const roleNames = {
  1: '超管',
  2: '管理员',
  3: '成员',
};

export const reportTypes = [
  {
    id: 1,
    data: '发布广告',
  },
  {
    id: 2,
    data: '诈欺诈骗',
  },
  {
    id: 3,
    data: '垃圾讯息',
  },
  {
    id: 4,
    data: '其他',
  },
];

export const messageSize = 20;
export const joinRoom = 500000;
export const latestHistory = 0;

type ModalViewType = {
  component: React.ComponentType<any>;
};

export enum ModalTypes {
  NORMAL = 'normal',
  BULLETIN = 'bulletin',
}

export const ModalsMap: Map<ModalTypes, ModalViewType> = new Map([
  [
    ModalTypes.NORMAL,
    {
      component: Modal,
    },
  ],
  [
    ModalTypes.BULLETIN,
    {
      component: RuleBoard,
    },
  ],
]);

export enum ModalActionTypes {
  BAN = 'ban',
  UN_BAN = 'un_ban',
  DELETE_MSG = 'delete_msg',
  DELETE_MSGS = 'delete_msgs',
  REPORT = 'report',
}
