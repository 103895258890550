export interface OptionItemProps {
  image?: string;
  text?: string;
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const OptionItem = ({ image, text, onClick }: OptionItemProps) => {
  return (
    <button
      className='text-center justify-center items-center flex-col w-12 py-2'
      onClick={onClick}
    >
      <img src={image} className='w-6 h-6 mb-1 m-auto ' />
      <p className='text-333 text-sm border-r w-full mr-4'>{text}</p>
    </button>
  );
};
