import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<
    IChatService.chatUsers[],
    {
      rid: number;
      page_limit: number;
      current_page: number;
      is_muted: number;
      name?: string;
    }
  >({
    query: (params) => {
      return {
        url: '/api/v1/room/getUsers',
        method: 'GET',
        params,
      };
    },
  });
