import React, { useState } from 'react';

interface SwitchProps {
  className?: string;
  defaultStatus?: boolean;
  onChange?: (isCheck: boolean) => void;
}

export const Switch = ({
  className,
  defaultStatus = false,
  onChange,
  ...props
}: SwitchProps) => {
  const [check, setCheck] = useState(defaultStatus);
  return (
    <label className='inline-flex items-center cursor-pointer'>
      <input
        type='checkbox'
        value=''
        className='sr-only peer'
        onChange={(e) => {
          setCheck(e?.target?.checked);
          onChange && onChange(e?.target?.checked);
        }}
        checked={check}
        data-testid='switch'
      />
      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-primary after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:primary after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary" />
    </label>
  );
};
