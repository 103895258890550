import React, { useEffect, useMemo, useRef } from 'react';
import './marquee.css';

interface MarqueeProps {
  content?: string;
  containerStyles?: { [key: string]: any };
  textStyles?: { [key: string]: any };
  icon?: string;
  onFinish?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Marquee = ({
  containerStyles,
  textStyles,
  content,
  icon,
  onFinish,
  ...props
}: MarqueeProps) => {
  const marqueeRef = useRef<any>();
  const endListener = useRef(false);
  const duration = useMemo(() => {
    const contentLength = content.length;
    const fontSizeWidth = 12;
    const estimateScrollElementLength = contentLength * fontSizeWidth;
    const animationSpeed = 70;
    const animationDuration = Math.floor(
      estimateScrollElementLength / animationSpeed
    );
    return animationDuration < 10 ? 10 : animationDuration;
  }, [content]);

  const _onFinish = () => {
    onFinish && onFinish();
  };

  useEffect(() => {
    return () => {
      marqueeRef?.current?.removeEventListener(
        'animationiteration',
        _onFinish,
        false
      );
    };
  }, []);

  useEffect(() => {
    marqueeRef?.current?.removeEventListener(
      'animationiteration',
      _onFinish,
      false
    );
    endListener.current = false;
    if (marqueeRef.current && !endListener.current) {
      endListener.current = true;
      marqueeRef?.current?.addEventListener(
        'animationiteration',
        _onFinish,
        false
      );
    }
  }, [content]);

  return (
    <div className='flex h-8 w-full items-center'>
      {icon && <img src={icon} className='w-5 h-5' />}
      <div className='relative flex-1 h-8 overflow-hidden pr-1'>
        <div
          ref={marqueeRef}
          className='absolute leading-8 left-full top-0 min-w-full whitespace-nowrap'
          style={{
            animation: `${duration}s linear infinite scrollPercent`,
          }}
        >
          <span className='text-666 text-xs' style={textStyles}>
            {content}
          </span>
        </div>
      </div>
    </div>
  );
};
