import { OptionItem, OptionItemProps } from './optionItem';

interface OptionListProps {
  data?: OptionItemProps[];
}

/**
 * Primary UI component for user interaction
 */
export const OptionList = ({ data }: OptionListProps) => {
  return (
    <div className='text-center justify-center items-center flex-row w-full rtl rounded-lg bg-[#eee]'>
      {data.map((e, i) => {
        return <OptionItem key={i} {...e} />;
      })}
    </div>
  );
};
