import React, { MouseEventHandler } from 'react';
import debounce from 'lodash/debounce';

export interface IconButtonProps {
  source: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  enable?: boolean;
  className?: string;
  enableDebounce?: boolean;
  delay?: number;
}

export const IconButton = ({
  source,
  onClick,
  enable = true,
  className = '',
  enableDebounce = true,
  delay = 1000,
}: IconButtonProps) => {
  const iconStyle = !enable ? 'object-contain grayscale' : 'object-contain';

  const handleClick = enableDebounce ? debounce(onClick, delay) : onClick;

  return (
    <button
      className={`w-5 h-5 ${className}`}
      onClick={handleClick}
      data-testid='icon_btn'
    >
      <img className={`${iconStyle}`} src={source} alt="it's a button" />
    </button>
  );
};
