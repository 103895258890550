import { combineReducers } from 'redux';

import counter from 'redux/slices/counter';
import chatroom from './slices/chatroom';
import { api } from 'redux/services/api';

import { store } from './store';

const rootReducer = combineReducers({
  counter,
  chatroom,
  api: api.reducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
