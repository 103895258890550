import React from 'react';

export interface BadgeProps {
  text: string;
  positionType: 'top' | 'bottom' | 'none';
} 

export const Badge = ({text, positionType}: BadgeProps) => {
  let position = '';

  switch(positionType) {
    case 'bottom':
      position = 'absolute bottom-[-18px] right-1/2 translate-x-1/2';
      break;
    case 'none' :
      break;
  }

  return (
    <div className={`px-0.5 py-0 bg-pink-200 rounded-sm ${position}`} >
      <span className="text-primary text-sm text-nowrap leading-none text-break">{text}</span>
    </div>
  )
}