import React, {
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Search from 'stories/assets/search.png';
import Delete from 'stories/assets/delete.png';
import './input.css';

interface InputProps {
  mode?: 'normal' | 'search';
  className?: string;
  disable?: boolean;
  containerStyle?: { [key: string]: any };
  styles?: { [key: string]: any };
}

export interface InputHandler {
  getValue: () => string;
}

/**
 * Primary UI component for user interaction
 */
export const Input = forwardRef<InputHandler, InputProps>(
  (
    { mode = 'normal', className, disable, containerStyle, styles, ...props },
    ref
  ) => {
    const [value, setValue] = useState('');

    useImperativeHandle(ref, () => ({
      getValue: () => value,
    }));

    const classNameString = useMemo(() => {
      let classArr = [
        'input rounded outline-none py-2 px-3.5 text-333 bg-e6',
        `input-${mode}`,
      ];

      return `${classArr.join(' ')} ${className ?? ''}`;
    }, [disable, className, mode]);

    return (
      <div className='flex w-full relative' style={containerStyle}>
        {mode === 'search' && (
          <img
            src={Search}
            className='w-5 h5 absolute left-2.5 top-1/2 -translate-y-2.5'
          />
        )}
        <input
          type='text'
          className={classNameString}
          style={{ ...styles }}
          disabled={disable}
          value={value}
          onChange={(e) => {
            setValue(e?.target?.value);
          }}
          {...props}
          data-testid='input_field'
        />
        {value && !disable && (
          <img
            src={Delete}
            className='w-5 h5 absolute right-2.5 top-1/2 -translate-y-2.5 cursor-pointer'
            onClick={() => setValue('')}
            data-testid='input_field_delete'
          />
        )}
      </div>
    );
  }
);
