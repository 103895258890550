import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { setRoomDetail } from 'redux/actions';
import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<IChatService.chatroomDetailRes, { rid: number }>({
    query: (params) => {
      return {
        url: '/api/v1/room/getDetail',
        method: 'GET',
        params,
      };
    },
    providesTags: ['fetchChatroomDetail'],
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      await queryFulfilled;
      const { data } = await queryFulfilled;
      await dispatch(setRoomDetail(data));
    },
  });
