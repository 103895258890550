import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<
    IChatService.chatroomListRes[],
    { current_page: number; page_limit: number }
  >({
    query: (params) => {
      return {
        url: '/api/v1/room/index',
        method: 'GET',
        params,
      };
    },
  });
