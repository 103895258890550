import React, { ReactElement } from 'react';

export interface AvatarProps {
  source: string;
  size: 'sm' | 'md' | 'lg' | 'xl';
  hasBorder: boolean;
  badge?: () => ReactElement;
}

export const Avatar = ({ source, size, hasBorder, badge=null }: AvatarProps) => {
  const sizeClasses = {
    sm: 'w-[30px] h-[30px]',
    md: 'w-10 h-10',
    lg: 'w-[50px] h-[50px]',
    xl: 'w-[68px] h-[68px]'
  }

  const borderStyle = hasBorder ? 'rounded-full border-4 border-white' : 'rounded-full';

  return (
    <div className={`relative ${sizeClasses[size]}`}>
      <div className={`${sizeClasses[size]} ${borderStyle}`}>
        <img className={`${sizeClasses}`} src={source} alt="user's avatar" />
      </div>
      { badge ? badge() : null }
    </div>
    )
};
