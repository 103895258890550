import React, { useState, useRef, useEffect, useCallback } from 'react';

const HEARTBEAT_INTERVAL = 5 * 1000; // 20 sec
let timer = null;

const useWebsocket = ({ url, rid, uid }) => {
  const wsRef = useRef(null);
  const [message, setMessage] = useState();
  const disableRef = useRef(false);
  // heart beat check
  const heartBeat = useCallback(() => {
    try {
      if (timer) clearInterval(timer);

      timer = setInterval(() => {
        if (wsRef.current && wsRef.current.readyState === 1) {
          // console.log(wsRef.current.readyState)
          wsRef.current?.send(JSON.stringify({ an: 12 }));
        }
      }, HEARTBEAT_INTERVAL);
    } catch (err) {
      if (timer) {
        clearInterval(timer);
      }
    }
  }, []);

  const createWebSocket = useCallback(() => {
    const stateArr = [
      { key: 0, value: '正在链接中' },
      { key: 1, value: '已经链接并且可以通讯' },
      { key: 2, value: '连接正在关闭' },
      { key: 3, value: '连接已关闭或者没有链接成功' },
    ];

    if (timer) {
      clearInterval(timer); // 清除旧的定时器
    }

    if (wsRef.current && wsRef.current?.readyState === 1) return;

    if (wsRef.current && wsRef.current?.readyState === 3) {
      // wsRef.current?.close()
      wsRef.current = null;
    }
    if (disableRef.current) return;

    try {
      wsRef.current = new WebSocket(url);
      wsRef.current.onopen = () => {
        heartBeat();
      };

      wsRef.current.onclose = (event) => {
        const closeCode = event.code;

        // 可以根据不同的关闭代码执行特定的处理逻辑
        if (closeCode === 1000) {
          console.log('Normal closure, no errors.');
        } else if (closeCode === 1006) {
          console.log('Connection closed abnormally. Check your server.');
        } else {
          console.log('Other close code. Handle accordingly.');
        }
        console.log('WebSocket closed. Trying to reconnect in 3 seconds.');
        setTimeout(() => {
          createWebSocket();
        }, 3000);
      };

      wsRef.current.onerror = (e) => {
        console.log('error', e);
      };

      wsRef.current.onmessage = (e) => {
        setMessage(e.data);
      };
    } catch (error) {
      console.log(error);
    }
  }, [wsRef]);

  const webSocketInit = useCallback(() => {
    if (!wsRef.current || wsRef.current.readyState === 3) {
      createWebSocket();
    }
  }, [wsRef]);

  const closeWebSocket = useCallback(() => {
    // wsRef.current?.close()
    wsRef.current = null;
  }, [wsRef]);

  const disableReconnect = () => {
    disableRef.current = true;
    wsRef.current?.close();
  };

  const reconnect = () => {
    closeWebSocket();
    try {
      webSocketInit();
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = (message) => {
    console.log({ ...message, rid, uid });
    wsRef.current?.send(JSON.stringify({ ...message, rid, uid }));
  };

  useEffect(() => {
    if (url) {
      webSocketInit();
      return () => {
        wsRef.current?.close();
      };
    }
  }, [wsRef, url]);

  return {
    message,
    closeWebSocket,
    reconnect,
    sendMessage,
    disableReconnect,
  };
};

export default useWebsocket;
