import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IChatService } from 'redux/services/modules/chat/chat';

interface IChatroom {
  replyMsg: any;
  roleInfo: any;
  images: { rawFile: File; url: string }[];
  atContent: {
    userName: string;
    userId: number;
  } | null;
  resetSelectTimeStamp: number;
  emoji: { id: number; name: string } | null;
  roomDetail: IChatService.chatroomDetailRes | null;
  ruleInit: boolean;
}

const initialState: IChatroom = {
  replyMsg: null,
  roleInfo: null,
  images: [],
  atContent: null,
  resetSelectTimeStamp: 0,
  emoji: null,
  roomDetail: null,
  ruleInit: false,
};

const counterSlice = createSlice({
  name: 'chatroom',
  initialState,
  reducers: {
    setReplyMsg: (state, { payload }) => {
      state.replyMsg = payload;
    },
    setRoleInfo: (state, { payload }) => {
      state.roleInfo = payload;
    },
    setImages: (state, { payload }) => {
      state.images = payload;
    },
    setAtContent: (state, { payload }) => {
      state.atContent = payload;
    },
    setSelectTimeStamp: (state) => {
      state.resetSelectTimeStamp = dayjs().valueOf();
    },
    setEmoji: (state, { payload }) => {
      state.emoji = payload;
    },
    setRoomDetail: (state, { payload }) => {
      state.roomDetail = payload;
    },
    setRuleInit: (state, { payload }) => {
      state.ruleInit = payload;
    },
    resetWithoutRule: (state) => ({
      ...initialState,
      ruleInit: state.ruleInit,
    }),
    reset: (state) => initialState,
  },
});

export const {
  setReplyMsg,
  setRoleInfo,
  setImages,
  setAtContent,
  setSelectTimeStamp,
  setEmoji,
  setRoomDetail,
  setRuleInit,
  resetWithoutRule,
  reset,
} = counterSlice.actions;

export default counterSlice.reducer;
