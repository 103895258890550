import { Header } from './../../components/header/index';
import Config from 'config';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import {
  encrypt,
  generateSign,
  getTimeDiff,
  responseDecrypt,
  getEncryptCode,
  getShortServername,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';

import isNil from 'lodash/isNil';
import { setImageDomain } from 'utils';
// import errorHandler from './errorHandler'

export const userInfo = {
  address: '',
  admin_id: 2,
  avatar: 'https://gwbd-res.kpkpo.com/mark-six-2.0/user_avatar/icon.png',
  avatar_tmp: '/mark-six-2.0/user_avatar/icon.png',
  cash_balance: '200.00',
  channel: '',
  coin_balanc: '1204.10',
  create_time: '2022-05-31 10:22:33',
  dateline: 1653963753,
  device_id: '',
  id: 148076,
  invite: '',
  ip: '211.75.133.160',
  is_valid: 1,
  last_ip: '218.211.60.58',
  last_login_time: 1710743502,
  last_thread_time: 1664522652,
  name: 'darren123',
  nick_name: 'darren1234',
  nick_name_modify: 1,
  num_fans: 63,
  num_reply: 174,
  num_spread: 0,
  num_thread: 41,
  pass: 'd7d30b6aa0462dfa4e59a00f261df560',
  phone: '91653963753',
  power: 0,
  realname: '',
  status: 100,
  token: '148076|1bb681f999fec72f437a96e445ba7e7a|1713254378',
};

const [, chatToken] = userInfo?.token.split('|');

const encryptKey = getEncryptCode(process.env.REACT_APP_CHAT_ROOM_URL);

const isSpecificAPI = (path: string) => {
  const specificAPI = [
    '/api/v2/User/signIn',
    '/api/v2/User/signUp',
    // '/api/User/specialAgentSignIn',
  ];
  return specificAPI.some((v) => path.includes(v));
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_ROOM_URL,
});

axiosInstance.defaults.timeout = Config.TIMEOUT;

const _originalPost = axiosInstance.post;
const _originalGet = axiosInstance.get;

// const _post = async (url: string, data: {}, cacheConfig: {}) => {
//   const res = await _originalPost(url, data, {
//     headers: { 'Content-Type': 'multipart/form-data', },
//   });

const _get = async (url: string, params: {}, cacheConfig: {}) => {
  const res = await _originalGet(url, {
    headers: { 'Cache-Control': 'no-cache' },
  });

  return res;
};

let captchaToken = '';

axiosInstance.interceptors.response.use(
  (response) => {
    const { method, url } = response?.config ?? { method: 'POST', url: '' };

    if (isNil(response)) {
      return Promise.reject(response);
    }

    if (typeof response?.data !== 'object') {
      response.data = responseDecrypt(response?.data, encryptKey);
    }

    if (response?.status !== 200) {
      // errorHandler(response, null);

      return Promise.reject(response);
    }

    // const headers = response?.headers;

    // if (headers) {
    //   const token = (headers?.['auth-token'] as string) ?? null;
    //   const identity = headers?.['auth-identity'] as string;
    //   captchaToken = headers?.['x-captcha-token'] as string;
    // }

    return response?.data || {};
  },
  (err) => {
    if (err.message.indexOf('413') > -1 || err.message.indexOf('404') > -1) {
      return Promise.reject(err);
    }
    const errData = err.message.indexOf('403') ? err?.response : err;

    // errorHandler(errData, null);
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const encryptToken = `${config?.data?.timestamp}@${getShortServername()}`;
    const beforeProcessing = config.data;
    if (config && isSpecificAPI(config?.url || '')) {
      config.data = {
        data: encrypt(JSON.stringify(config.data), encryptKey),
      };
    }

    const encrypted = encrypt(encryptToken, encryptKey);
    config.headers = {
      ...config.headers,
      // Sign: generateSign(
      //   beforeProcessing,
      //   'JU076TFU-C483929E-2BDF8E9F-76TG45LJ'
      // ),
      // 'X-Channel-Name': encodeURIComponent('App Store'),
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'X-Encrypt-Type': 1,
      // 'verification-token': encodeURIComponent(encrypted),
      // ...(captchaToken && { 'x-captcha-token': captchaToken }),
      Authorization: `Bearer ${chatToken}`,
    };

    return config as AxiosRequestConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params, headers = {} }) => {
    const timeDiff = getTimeDiff();
    const timestamp = Math.round((Date.now() - timeDiff) / 1000);
    try {
      if (method?.toLowerCase() === 'post') {
        const result = await _originalPost(url, body, {
          headers,
        });
        // const result = await _originalPost(url, {
        //   ...(!isNil(body) && { ...body }),
        //   nonce: uuidv4(),
        //   timestamp,
        // });
        if ((result as any)?.urls) {
          return { data: { urls: (result as any)?.urls } };
        }
        return { data: result?.data ?? {} };
      } else {
        const result = await _originalGet(url, {
          params,
        });
        if ((result as any)?.url || (result as any)?.u) {
          setImageDomain((result as any)?.url ?? (result as any)?.u ?? '');
        }
        return {
          data: result?.data ?? {},
        };
      }
    } catch (axiosError) {
      let err = axiosError as any;
      console.log({ err });
      // errorHandler(null, err);

      if (err?.data) {
        return {
          error: {
            status: err?.data?.errorcode,
            message: err?.data?.message || err.message,
            data: err?.data?.data || {},
          },
        };
      }

      return {
        error: {
          status: err?.response?.data?.code,
          message: err?.response?.data?.message,
          data: err?.response?.data || {},
        },
      };
    }
  };

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [],
});

export const get = _get;
