import React from 'react';

export interface EmptyPageProps {
  source: string;
  message: string;
}

export const EmptyPage = ({ source, message }: EmptyPageProps) => {
  return (
    <div
      className='bg-f7 h-full flex items-center justify-center'
    >
      <div className='flex flex-col items-center'>
        <img src={source} alt='Page Icon' />
        <p className='text-999 whitespace-pre-line text-center'>{message}</p>
      </div>
    </div>
  );
};
