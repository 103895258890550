import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { IChatService } from './chat';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<IChatService.reactionsRes[], { mid: number }>({
    query: (params) => {
      return {
        url: '/api/v1/message/getReactions',
        method: 'GET',
        params,
      };
    },
  });
