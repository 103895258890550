import React, { useMemo } from 'react';

const RouteLayout = ({ children, showBottomNav }) => {
  const bottomClassName = useMemo(
    () => (showBottomNav ? 'pb-[50px]' : ''),
    [showBottomNav]
  );

  return (
    <div className={`h-[100%] w-full ${bottomClassName}`}>
      {children}
      {showBottomNav ? (
        <div className='w-full fixed bottom-0 h-[50px] bg-white border-t border-ea flex justify-center items-center'>
          bottom nav
        </div>
      ) : null}
    </div>
  );
};

export default RouteLayout;
