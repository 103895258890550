import React, { useState, forwardRef, useImperativeHandle } from 'react';
import close from 'stories/assets/close.png';
import { ModalActionTypes } from 'definitions';
import { useLocation, useNavigate } from 'react-router-dom';

interface RuleBoardProps {
  title: string;
  content: string;
}

const RuleBoard = ({ title, content }: RuleBoardProps) => {
  const navigate = useNavigate();

  return (
    <div
      className='h-dvh fixed z-[90] bg-black/[0.6] top-0 flex flex-col justify-center items-center window-max-confg'
      onClick={() => {
        navigate(-1);
      }}
    >
      <div className='relative max-w-19rem max-h-[400px] min-w-19rem min-h-[200px] bg-white rounded-lg p-2'>
        <div className='w-full h-full overflow-hidden'>
          {title && (
            <div className='flex items-center justify-center  rounded-t'>
              <h3 className='text-xl font-semibold text-gray-900'>{title}</h3>
              <img src={close} className='w-[20px] h-[20px] absolute right-2' />
            </div>
          )}
          <p className='leading-relaxed text-333 whitespace-normal break-all p-2 overflow-y-scroll h-full pb-[30px]'>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RuleBoard;
