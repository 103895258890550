import { api } from 'redux/services/api';
import fetchChatroomList from './fetchChatroomList';
import fetchChatroomDetail from './fetchChatroomDetail';
import fetchUserInfo from './fetchRoleInfo';
import fetchUsersList from './fetchUsersList';
import updateRoomMuted from './editChatroomDetail';
import uploadImage from './uploadImage';
import muteUser from './muteUser';
import fetchReactionsList from './fetchReactionsList';
import postReport from './postReport';

export const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchChatroomList: fetchChatroomList(build),
    fetchChatroomDetail: fetchChatroomDetail(build),
    fetchUserInfo: fetchUserInfo(build),
    fetchUsersList: fetchUsersList(build),
    updateRoomMuted: updateRoomMuted(build),
    uploadImage: uploadImage(build),
    muteUser: muteUser(build),
    fetchReactionsList: fetchReactionsList(build),
    postReport: postReport(build),
  }),
});

export const {
  useLazyFetchChatroomListQuery,
  useLazyFetchChatroomDetailQuery,
  useFetchChatroomDetailQuery,
  useFetchUserInfoQuery,
  useFetchUsersListQuery,
  useLazyFetchUsersListQuery,
  useUpdateRoomMutedMutation,
  useLazyUploadImageQuery,
  useLazyMuteUserQuery,
  useLazyFetchReactionsListQuery,
  useLazyPostReportQuery,
} = chatApi;
