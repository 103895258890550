import toast from 'react-hot-toast';

export const showToast = (text: string) => {
  toast.custom(
    <div className='bg-gray-900 px-4 py-2 text-white text-base rounded bg-opacity-80'>
      {text}
    </div>,
    { duration: 1000 }
  );
};
