import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IChatService } from './chat';

const postReport = (build: EndpointBuilder<any, any, any>) => 
  build.query<IChatService.report, any>({
    query: (body) => {
      return {
        url: '/api/v1/message/report',
        method: 'POST',
        body,
      }
    }
  });
;

export default postReport;
