import React, { useEffect, useState } from 'react';
import { Button, Input, Avatar, Marquee, PageHeader } from 'stories';
import { useLazyFetchChatroomListQuery } from 'redux/services/modules/chat';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { IChatService } from 'redux/services/modules/chat/chat';
import { getImageDomain } from 'utils';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from 'pages/components/loading';
import { userInfo } from 'redux/services/api';
import PageWrapper from 'components/hoc/pageWrapper';
import { useAppDispatch } from 'redux/store';
import { reset } from 'redux/actions';

const ChatroomList = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState<IChatService.chatroomListRes[]>([]);
  const [pageConfig, setPageConfig] = useState({
    current_page: 1,
    page_limit: 20,
    isAllLoad: false,
  });
  const [domain, setDomain] = useState(getImageDomain());
  const [fetchRoomsList, { data, isLoading, isFetching }] =
    useLazyFetchChatroomListQuery();

  const scrollRef = useBottomScrollListener(() => {
    if (data?.length === pageConfig.page_limit && !isFetching) {
      setPageConfig((prev) => ({
        ...prev,
        current_page: prev.current_page + 1,
      }));
    }
  });

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    fetchRoomsList({
      current_page: pageConfig.current_page,
      page_limit: pageConfig.page_limit,
    })
      .unwrap()
      .then((res) => {
        if (res?.length < pageConfig.page_limit) {
          setPageConfig((prev) => ({ ...prev, isAllLoad: true }));
        }
        setDomain(getImageDomain());
        setList((prev) => [...prev, ...res]);
      });
  }, [pageConfig.current_page]);

  return (
    <div className='page-content-wrap bg-[#f7f7f7] h-[100%] w-full'>
      <PageHeader title='聊天室列表' showBack={false} />
      {isLoading ? (
        <Loading size={12} isFullH />
      ) : (
        <div
          id='roomlist-container'
          ref={scrollRef as React.RefObject<HTMLDivElement>}
          className='pt-4 overflow-y-auto h-[100%]'
        >
          {list?.map((e) => (
            <div
              key={e?.id}
              className='room mb-4 flex bg-white items-center px-[14px] py-[12px]'
            >
              <div className='mr-2'>
                <Avatar
                  size='lg'
                  hasBorder={false}
                  source={`${domain}${e.icon}`}
                />
              </div>
              <div className='flex-1 mr-2'>
                <p
                  data-test='room-name'
                  className='text-sm font-semibold text-[#333] truncate h-5 '
                >
                  {e.name}
                </p>
                <Marquee content={e.describe} />
              </div>

              <Button
                data-test='button'
                label='进入'
                type='primary'
                className='h-7 px-4'
                onClick={() => {
                  const [, chatToken] = userInfo?.token.split('|');
                  navigate('/chatroom', {
                    state: {
                      roomId: e.id,
                      userInfo: { ...userInfo, token: chatToken },
                      title: e.name,
                    },
                  });
                }}
              />
            </div>
          ))}
          {!isLoading && isFetching && list?.length > 0 ? (
            <Loading size={6} />
          ) : null}
          {pageConfig.isAllLoad ? (
            <p className='text-center text-999 pb-4 text-xs no-more'>
              没有更多了
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PageWrapper(ChatroomList);
