import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Close from 'stories/assets/close.png';
import './bottomSheet.css';

const HEADER_HEIGHT = 40;

interface BottomSheetProps {
  title: string;
  boldTitle?: boolean;
  content?: (payload?: any) => JSX.Element;
  sheetHeight?: number;
  onClose?: () => void;
}

export interface BottomSheetHandler {
  open: (payload?: any) => void;
  close: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const BottomSheet = forwardRef<BottomSheetHandler, BottomSheetProps>(
  (
    { title, content: Content, sheetHeight = 300, onClose, boldTitle = null },
    ref
  ) => {
    const [status, setStatus] = useState({
      show: false,
      init: false,
    });
    const [payload, setPayload] = useState({});

    useImperativeHandle(ref, () => ({
      open: (payload) => {
        setStatus({ show: true, init: true });
        setPayload(payload);
      },
      close: () => {
        setStatus((prev) => ({ ...prev, show: false }));
      },
    }));

    const animationName = useMemo(() => {
      if (!status.init) {
        return '';
      } else {
        return status.show ? 'scrollUp' : 'scrollDown';
      }
    }, [status]);

    const bottomInset = useMemo(() => {
      if (!status.init) {
        return -(sheetHeight + HEADER_HEIGHT);
      } else {
        return status.show ? -(sheetHeight + HEADER_HEIGHT) : 0;
      }
    }, [status, sheetHeight]);

    return (
      <>
        {status.show ? (
          <div
            className='h-dvh fixed z-[50] bg-black/[0.6] top-0 window-max-confg'
            onClick={() => {
              setStatus((prev) => ({ ...prev, show: false }));
            }}
            data-testid='bottom_close'
          />
        ) : null}

        <div
          className='window-max-confg absolute z-[60] bg-white'
          style={{
            bottom: bottomInset,
            height: sheetHeight + HEADER_HEIGHT,
            animation: `${animationName} 0.4s ease forwards`,
          }}
        >
          <div
            className='relative border-ea border-y px-8 bg-white'
            style={{ height: '40px' }}
          >
            <h3
              className={`text-center font-semibold one-line-dot ${
                boldTitle ? 'font-bold' : ''
              }`}
              style={{ lineHeight: '40px' }}
            >
              {title}
            </h3>
            <img
              src={Close}
              className='h-5 w-5 absolute right-4 top-1/2 -translate-y-2.5 cursor-pointer'
              onClick={() => {
                setStatus((prev) => ({ ...prev, show: false }));
                onClose && onClose();
              }}
            />
          </div>

          {Content && (
            <div
              className='overflow-y-auto'
              style={{
                maxHeight: sheetHeight,
                bottom:
                  typeof sheetHeight === 'number'
                    ? -(sheetHeight - HEADER_HEIGHT)
                    : '-100vh',
              }}
            >
              <Content payload={payload} />
            </div>
          )}
        </div>
      </>
    );
  }
);
