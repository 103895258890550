import React, { Suspense } from 'react';
// import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'styles/global.scss';
import './i18n';
import store, { persistor } from 'redux/store';
import { Toaster } from 'react-hot-toast';
import { PersistGate } from 'redux-persist/integration/react';
import RouteLayout from './components/routeLayout';
import ModalContainer from 'components/ModalContainer';
import { Link, Routes, Route, useLocation, Outlet } from 'react-router-dom';
import Loading from 'pages/components/loading';

import Home from 'pages';
import ChatroomList from 'pages/chatroomList';

const Example = React.lazy(() => import('./pages/exmaple'));
const Chatroom = React.lazy(() => import('./pages/chatroom'));
const ChatSetting = React.lazy(() => import('./pages/chatSetting'));
const ChatUsers = React.lazy(() => import('./pages/chatUsers'));
const DisableUsers = React.lazy(() => import('./pages/disableUsers'));
const ReportMessage = React.lazy(() => import('./pages/reportMessage'));

const SuspenseLayout = () => {
  return (
    <React.Suspense fallback={<Loading size={12} isFullH />}>
      <Outlet />
    </React.Suspense>
  );
};

const routeConfig = [
  { path: '/', element: <ChatroomList />, showBottomNav: false },
  { path: 'example', element: <Example />, showBottomNav: false },
  { path: 'chatroom', element: <Chatroom />, showBottomNav: false },
  { path: 'chatSetting', element: <ChatSetting />, showBottomNav: false },
  { path: 'chatUsers', element: <ChatUsers />, showBottomNav: false },
  { path: 'disableUsers', element: <DisableUsers />, showBottomNav: false },
  { path: 'report', element: <ReportMessage />, showBottomNav: false },
];

function App(): JSX.Element {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  return (
    <Provider store={store}>
      <Toaster
        containerStyle={{
          top: '50%',
        }}
      />

      <Routes location={previousLocation || location}>
        <Route element={<SuspenseLayout />}>
          {routeConfig.map(({ path, element, showBottomNav = true }) => (
            <Route
              key={path}
              path={path}
              element={
                <RouteLayout showBottomNav={showBottomNav}>
                  {element}
                </RouteLayout>
              }
            />
          ))}
        </Route>
      </Routes>

      {previousLocation && <ModalContainer />}
    </Provider>
  );

  // return <ChatroomList />;
}

export default App;
