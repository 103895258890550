import React, { useMemo } from 'react';
import './button.css';
import Speaker from 'stories/assets/speaker.png';
import debounce from 'lodash/debounce';

interface ButtonProps {
  className?: string;
  type?: 'primary' | 'secondary' | 'icon';
  icon?: string;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'fullwidth';
  label?: string;
  onClick?: () => void;
  styles?: { [key: string]: any };
  disable?: boolean;
  enableDebounce?: boolean;
  delay?: number;
}

const ButtonType = {
  primary: 'button--primary',
  secondary: 'button--secondary',
  icon: 'button--icon',
};

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  className,
  type = 'primary',
  size = 'medium',
  icon = Speaker,
  backgroundColor,
  label,
  styles,
  disable,
  onClick = () => {},
  enableDebounce = false,
  delay = 1000,
  ...props
}: ButtonProps) => {
  const mode = ButtonType?.[type ?? 'primary'];

  const _onClick = () => {
    if (!disable) {
      onClick && onClick();
    }
  };

  const handleClick = enableDebounce ? debounce(_onClick, delay) : _onClick;

  const classNameString = useMemo(() => {
    let classArr = [
      'font-semibold border-0 rounded cursor-pointer inline-block leading-none',
      `button--${size}`,
      mode,
    ];
    if (disable) {
      classArr.push('button-disable');
    }
    return `${classArr.join(' ')} ${className}`;
  }, [disable, size, className, mode]);

  if (type === 'icon' && icon) {
    return (
      <button
        type='button'
        className={`${mode} ${className}`}
        style={{ backgroundColor, ...styles }}
        onClick={handleClick}
        {...props}
      >
        <img src={icon} className='w-5 h-5' />
      </button>
    );
  }

  return (
    <button
      type='button'
      className={classNameString}
      style={{ backgroundColor, ...styles }}
      onClick={handleClick}
      {...props}
      data-testid='button'
    >
      {label}
    </button>
  );
};
