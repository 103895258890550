import md5 from 'md5';
import isNil from 'lodash/isNil';

export const encrypt = (cipherText: string, encryptKey: string) => {
  let encrypted = '';
  const cipherChars = Array.from(cipherText);
  const encryptKeyChars = Array.from(encryptKey);

  for (let i = 0; i < cipherChars.length; i++) {
    encrypted += String.fromCharCode(
      cipherChars[i].charCodeAt(0) ^
        encryptKeyChars[i % encryptKeyChars.length].charCodeAt(0)
    );
  }

  return encrypted;
};

export const getShortServername = (serverName: string = '') => {
  const urls = serverName?.split('//').filter((name: string) => name);

  if (urls.length) {
    const domains = urls[1].split('.').filter((name: string) => name);

    if (domains.length) {
      const shortServername = domains[0]
        .split('-fs')
        .filter((name: string) => name);

      if (shortServername.length) {
        return shortServername[0];
      }
    }
  }

  return 'fusion';
};

export const getEncryptCode = (serverName: string = '') => {
  const shortDomainName = getShortServername(serverName);
  return /fusion/.test(shortDomainName)
    ? '23480fdakfldak0o9dkl9902kld04kll' //dev
    : 'f7664c7ede31eda6c707d6df01b8a015';
};

export const responseDecrypt = (cipherText: string, encryptKey: string) => {
  if (isNil(cipherText)) {
    return '';
  }

  const decrypted = encrypt(cipherText, encryptKey);

  return JSON.parse(decrypted);
};

export const isSpecificAPI = (path: string) => {
  const specificAPI = [
    '/api/v2/User/signIn',
    '/api/v2/User/signUp',
    // '/api/User/specialAgentSignIn',
  ];
  return specificAPI.some((v) => path.includes(v));
};

const sortNstringify = (data: any, str = '') => {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      str += JSON.stringify(data);
      return str;
    } else {
      Object.keys(data)?.forEach((key) => {
        str += ',"' + key + '":' + sortNstringify(data[key]);
      });
      return '{' + str.substr(1) + '}';
    }
  } else {
    return JSON.stringify(data);
  }
};

export const generateSign = (data: any, signKey: string) => {
  let sign = '';
  Object.keys(data)
    .sort()
    ?.forEach((key) => {
      if (typeof data[key] !== 'undefined' && data[key] !== '') {
        if (typeof data[key] === 'object') {
          sign += `&${key}=${sortNstringify(data[key])}`;
        } else {
          sign += `&${key}=${data[key]}`;
        }
      }
    });
  sign = sign.substr(1) + signKey;
  return md5(sign);
};

export const formatText = (text) => {
  if (!text || text.trim().length === 0) return '';
  const regex = /#format#@(.*?)##/g;

  return text.replace(regex, function (match, username) {
    return `@${username} `;
  });
};

export const getParsedPattern = (text) => {
  const regex = /#format#@(.*?)##/g;
  let match;
  let patterns = [];

  while ((match = regex.exec(text)) !== null) {
    const userName = match[1];
    const processText = userName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const pattern = new RegExp(`@${processText}`, 'g');
    patterns.push(pattern);
  }

  return patterns;
};
